import { TableMeta } from '@/components/data-table/DataTable';
import { Earthquake } from '@/components/PeTAT/PeTAT';
import { HistoricalReport } from '@/models/HistoricalReport';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Report, Answer } from '@/models/Report';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { organizationUuid } from '@/models/Organization';
import Client from '@/support/Client';
import { debounce } from 'lodash';

@Component<SearchModule>({
  filters: {
    distance: (distance: number) => {
      const kilometerCalculation = distance / 1000;
      return new Intl.NumberFormat('nl-NL', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }).format(kilometerCalculation);
    },
    magnitude: (magnitude: number) => new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(magnitude),
    pgv: (pgv: number) => new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(pgv),
  },
})
export default class SearchModule extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  protected isLoadingReportDetails = true;

  protected isLoading = false;

  protected searchInput = '';

  protected reports: Report[] | null = null;

  protected reportTrillingstool: Trillingstool[] | null = null;

  protected reportEarthquakes: Earthquake[] | null = null;

  protected reportHistoricalDamages: HistoricalReport[][] = [];

  protected mapCenter = {
    lat: 53.250748,
    lng: 6.513490,
  };

  protected payload: SearchPayload = {
    // zipCode: '1318dg',
    // zipCode: '7213EB',
    zipCode: '',
    number: '',
    search: '',
  }

  protected databaseDate = '0000-00-00 00:00:00';

  protected total = 0;

  protected lastPage = 1;

  protected from = 0;

  protected to = 0;

  protected page = 1;

  protected limit = 15;

  public paginationDebounce: Function = this.handlePagination();
  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted() {
    this.initializeSearchParam();
    // this.fetchReports();
  }
  // #endregion

  // #region Class methods

  protected getReportAnswer(report: Report, answerKey: string) {
    if (! report.answers) { return ''; }

    const answer = report.answers?.find((answer: Answer) => answer.key === answerKey);

    return answer ? answer.value : '';
  }

  protected getCircleOptions(earthquake: Earthquake) {
    const large = earthquake.magnitude > 2.9;
    const medium = earthquake.magnitude > 1.9 && earthquake.magnitude < 3.0;

    const color = earthquake.gasfield ? 'red' : 'grey';

    let stokeWeight = 1;
    if (medium) {
      stokeWeight = 2;
    }
    if (large) {
      stokeWeight = 3;
    }

    return {
      strokeColor: color,
      strokeOpacity: 1,
      strokeWeight: stokeWeight,
      fillColor: 'orange',
      fillOpacity: 0.05,
    };
  }

  protected async applyFilters() {
    this.isLoading = true;
    await this.fetchReports();
    await this.fetchTrillingstool();
    await this.fetchEarthquakes();
    await this.historicalReportsPromises();
    this.isLoading = false;
  }

  protected handlePagination() {
    return debounce(
      (page: number) => {
        this.page = page;
        this.fetchReports();
      },
      300,
    );
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async fetchReports() {
    const reports = await new Report()
      .include(['applicant', 'answers', 'damages', 'organization'])
      .filter({
        postcodes: this.payload.zipCode ? [this.payload.zipCode] : [],
        houseNumber: this.payload.number || null,
        search: this.payload.search,
      })
      .sort('planned_at', 'ASC')
      .limit(15)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.total = reports[0] !== undefined ? (reports[0].meta as TableMeta).total : 0;
    this.lastPage = reports[0] !== undefined ? (reports[0].meta as TableMeta).last_page : 1;
    this.from = reports[0] !== undefined ? (reports[0].meta as TableMeta).from : 0;
    this.to = reports[0] !== undefined ? (reports[0].meta as TableMeta).to : 0;

    this.reports = reports || null;
  }

  protected async fetchTrillingstool() {
    if (! this.reports) { return; }
    const report = this.reports[0];

    if (! report || ! report.uuid) {
      return;
    }

    if (! report.address?.geolocation?.latitude || ! report.address?.geolocation?.longitude) {
      return;
    }

    const response = await new Report()
      .setTrilingstoolRealtimeEndpoint(report.uuid)
      .limit(10)
      .all();

    this.reportTrillingstool = response;
  }

  protected async fetchHistoricalReports(report: Report): Promise<HistoricalReport[] | []> {
    if (! report || ! report.uuid) {
      return [];
    }

    return await new HistoricalReport()
      .filter({ report: report.uuid })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchEarthquakes() {
    if (! this.reports) { return; }
    const report = this.reports[0];

    if (! report || ! report.uuid) {
      return;
    }

    if (! report.address?.geolocation?.latitude || ! report.address?.geolocation?.longitude) {
      return;
    }

    const payload = {
      'filters[from]': '2012-01-01',
      'filters[until]': '2022-12-31',
      'filters[postcode]': report.address?.postcode,
      'filters[number]': report.address?.number,
      'filters[number_add]': report.address?.number_add,
      'filters[magnitude_min]': '1.8',
      'filters[magnitude_max]': '10.0',
      'filters[statuses]': ['downloaded'],
      'filters[distance]': {
        latitude: report.address?.geolocation?.latitude,
        longitude: report.address?.geolocation?.longitude,
        kilometers: 0.5,
      },
      'with[earthquakes]': 1,
      'with[reports]': 1,
    };

    const response = await Client('get', '/dmz/tools/effectgebied', payload, false, organizationUuid.img)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.reportEarthquakes = response.data.earthquakes;
  }

  protected async historicalReportsPromises() {
    if (! this.reports) { return; }

    const historicalReportsPromises = this.reports.map((reports: Report) => this.fetchHistoricalReports(reports));
    const historicalReports: HistoricalReport[][] = await Promise.all(historicalReportsPromises);
    historicalReports.forEach((historicalReportArray) => {
      if (historicalReportArray?.length) {
        this.reportHistoricalDamages.push(historicalReportArray);
      }
    });
  }
  // #endregion

  // #region Getters & Setters

  // #endregion

  // #region @Watchers

  @Watch('$route.query.search', { deep: true })
  protected initializeSearchParam() {
    if (typeof this.$route.query.search === 'string') {
      this.payload.search = this.$route.query.search || '';
      this.applyFilters();
    }
  }

  @Watch('payload.search', { deep: true })
  protected payloadChanged() {
    window.history.pushState('', '', `?search=${this.payload.search}`);
  }
  // #endregion
}

interface Trillingstool {
  city: string;
  depth: string;
  distance: number;
  gasfield_id: string | null;
  magnitude: string;
  occurred_at: string;
  pgv_1pct: number;
  pgv_25pct: number;
  pgv_50pct: number;
}

interface SearchPayload {
  zipCode?: string;
  number?: string;
  search?: string;
}
