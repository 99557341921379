var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                _c(
                  "div",
                  { staticClass: "elementPanel" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "align-baseline": "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs5: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { "hide-details": "", label: "Zoeken" },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.applyFilters()
                                }
                              },
                              model: {
                                value: _vm.payload.search,
                                callback: function($$v) {
                                  _vm.$set(_vm.payload, "search", $$v)
                                },
                                expression: "payload.search"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs3: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { "hide-details": "", label: "Postcode" },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.applyFilters()
                                }
                              },
                              model: {
                                value: _vm.payload.zipCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.payload, "zipCode", $$v)
                                },
                                expression: "payload.zipCode"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs2: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "hide-details": "",
                                label: "Huisnummer"
                              },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.applyFilters()
                                }
                              },
                              model: {
                                value: _vm.payload.number,
                                callback: function($$v) {
                                  _vm.$set(_vm.payload, "number", $$v)
                                },
                                expression: "payload.number"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs2: "", "text-xs-right": "" } },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  color: "primary",
                                  small: "true",
                                  outline: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.applyFilters()
                                  }
                                }
                              },
                              [_vm._v("Zoeken")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm.isLoading
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("LoaderCard", {
                        attrs: {
                          flat: "",
                          type: "spinner--center",
                          minHeight: "300px"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isLoading && (!_vm.reports || !_vm.reports.length)
                ? _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("span", { staticClass: "fadedText" }, [
                      _vm._v("Geen dossiers gevonden")
                    ])
                  ])
                : _vm._e(),
              !_vm.isLoading && _vm.reports && _vm.reports.length
                ? [
                    _c(
                      "v-flex",
                      { attrs: { xs8: "", "mb-3": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                              _c("h2", { staticClass: "elementSubTitle" }, [
                                _vm._v("Dossiers")
                              ])
                            ]),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  !_vm.isLoading && _vm.reports.length
                                    ? _c(
                                        "v-layout",
                                        {
                                          staticClass: "item__list",
                                          attrs: { wrap: "" }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "label",
                                              attrs: {
                                                xs12: "",
                                                "font-weight-bold": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { wrap: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Dossier")
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._l(_vm.reports, function(
                                            report,
                                            index
                                          ) {
                                            return _c(
                                              "v-flex",
                                              {
                                                key:
                                                  "report-" +
                                                  report.uuid +
                                                  "-" +
                                                  index,
                                                staticClass: "list__item",
                                                attrs: { xs12: "" }
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { wrap: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs4: "" } },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                "/reports/" +
                                                                report.uuid
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                report.case_number
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              report.type.name
                                                            )
                                                          )
                                                        ]),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass: "mb-0"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Opname: " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateFormatTime"
                                                                  )(
                                                                    report.planned_at ===
                                                                      _vm.databaseDate
                                                                      ? "-"
                                                                      : report.planned_at
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs4: "" } },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              report.applicant
                                                                ? report
                                                                    .applicant
                                                                    .name
                                                                : "-"
                                                            )
                                                          )
                                                        ]),
                                                        report && report.address
                                                          ? _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: report.googleMapUrl(),
                                                                  target:
                                                                    "_blank"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          report
                                                                            .address
                                                                            .street
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          report
                                                                            .address
                                                                            .number
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          report
                                                                            .address
                                                                            .number_add
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c("br"),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          report
                                                                            .address
                                                                            .postcode
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          report
                                                                            .address
                                                                            .city
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs4: "" } },
                                                      [
                                                        report.expert
                                                          ? _c("p", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    report
                                                                      .expert
                                                                      .name
                                                                  ) +
                                                                  " ( "
                                                              ),
                                                              report.expert
                                                                .organization
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        report
                                                                          .expert
                                                                          .organization
                                                                          .name
                                                                      )
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(" ) ")
                                                            ])
                                                          : _vm._e(),
                                                        report.damages &&
                                                        report.damages.length
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "mb-0"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Schades: " +
                                                                    _vm._s(
                                                                      report
                                                                        .damages
                                                                        .length
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c("p", [
                                                          _vm._v(
                                                            "Schadebedrag: " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  report.payout_total
                                                                )
                                                              )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.reports && _vm.reports.length
                                    ? _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "justify-center": "",
                                            column: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {},
                                            [
                                              _c("v-pagination", {
                                                attrs: {
                                                  length: _vm.lastPage,
                                                  "total-visible": 7
                                                },
                                                on: {
                                                  input: _vm.paginationDebounce
                                                },
                                                model: {
                                                  value: _vm.page,
                                                  callback: function($$v) {
                                                    _vm.page = $$v
                                                  },
                                                  expression: "page"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { staticClass: "search-total" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.from) +
                                                    " - " +
                                                    _vm._s(_vm.to) +
                                                    " van " +
                                                    _vm._s(_vm.total)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs4: "", "mb-3": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                              _c("h2", { staticClass: "elementSubTitle" }, [
                                _vm._v("NAM history")
                              ])
                            ]),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  !_vm.isLoading &&
                                  _vm.reportHistoricalDamages.length
                                    ? [
                                        _vm._l(
                                          _vm.reportHistoricalDamages,
                                          function(
                                            reportHistoricalDamage,
                                            index
                                          ) {
                                            return [
                                              _c(
                                                "v-flex",
                                                {
                                                  key: "" + index,
                                                  attrs: {
                                                    xs12: "",
                                                    "pa-0": "",
                                                    "mb-3": ""
                                                  }
                                                },
                                                [
                                                  reportHistoricalDamage &&
                                                  reportHistoricalDamage.length
                                                    ? _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "filterContainer filterContainer__historical",
                                                          attrs: {
                                                            wrap: "",
                                                            "py-2": "",
                                                            "pl-3": "",
                                                            "pr-2": "",
                                                            "mb-2": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._l(
                                                            reportHistoricalDamage,
                                                            function(
                                                              historicalDamage
                                                            ) {
                                                              return _c(
                                                                "v-flex",
                                                                {
                                                                  key:
                                                                    historicalDamage.uuid,
                                                                  attrs: {
                                                                    shrink: "",
                                                                    "mr-3": ""
                                                                  }
                                                                },
                                                                [
                                                                  historicalDamage.answers
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "historical-damages--expert"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                historicalDamage
                                                                                  .answers
                                                                                  .historicalReport_Naamexpert ||
                                                                                  ""
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  historicalDamage.answers &&
                                                                  historicalDamage
                                                                    .answers
                                                                    .historicalReport_CaseNumber
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "historical-damages--report"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " - " +
                                                                              _vm._s(
                                                                                historicalDamage
                                                                                  .answers
                                                                                  .historicalReport_CaseNumber
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        )
                                      ]
                                    : _vm._e(),
                                  !_vm.isLoading &&
                                  !_vm.reportHistoricalDamages.length
                                    ? _c("span", { staticClass: "fadedText" }, [
                                        _vm._v(" Geen NAM dossiers gevonden ")
                                      ])
                                    : _vm._e()
                                ],
                                2
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "mb-3": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                              _c("h2", { staticClass: "elementSubTitle" }, [
                                _vm._v("Top 10 trillingssnelheden")
                              ])
                            ]),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  !_vm.isLoading &&
                                  _vm.reportTrillingstool &&
                                  _vm.reportTrillingstool.length
                                    ? _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "overflow-auto",
                                              attrs: { xs12: "" }
                                            },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "item__list item__list--table"
                                                },
                                                [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "label font-weight-bold",
                                                      attrs: {
                                                        wrap: "",
                                                        row: "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v("datum beving")
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("gasveld")
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("epicentrum")
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("afstand (km)")
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("magnitude")
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          "PGV 50% (mm/s)**"
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          "PGV 25% (mm/s)*"
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v("PGV 1% (mm/s)*")
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.reportTrillingstool,
                                                    function(
                                                      trillingstool,
                                                      trillingsIndex
                                                    ) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key: trillingsIndex,
                                                          staticClass:
                                                            "list__item"
                                                        },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "dateFormat"
                                                                )(
                                                                  trillingstool.occurred_at
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                trillingstool.gasfield_id ===
                                                                  1
                                                                  ? "G"
                                                                  : "A"
                                                              )
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                trillingstool.city
                                                              )
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "distance"
                                                                )(
                                                                  trillingstool.distance
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "magnitude"
                                                                )(
                                                                  trillingstool.magnitude
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f("pgv")(
                                                                  trillingstool.pgv_50pct
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f("pgv")(
                                                                  trillingstool.pgv_25pct
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f("pgv")(
                                                                  trillingstool.pgv_1pct
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isLoading &&
                                  (!_vm.reportTrillingstool ||
                                    !_vm.reportTrillingstool.length)
                                    ? _c("span", { staticClass: "fadedText" }, [
                                        _vm._v(
                                          " Geen trillingssnelheden gevonden "
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "mb-3": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                              _c("h2", { staticClass: "elementSubTitle" }, [
                                _vm._v("Trillingstool")
                              ])
                            ]),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _vm.reports
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              !_vm.isLoading
                                                ? _c(
                                                    "gmap-map",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        height: "400px"
                                                      },
                                                      attrs: {
                                                        center: _vm.mapCenter,
                                                        zoom: 9
                                                      }
                                                    },
                                                    [
                                                      _c("GmapMarker", {
                                                        attrs: {
                                                          position: {
                                                            lat:
                                                              _vm.reports[0]
                                                                .address
                                                                .geolocation
                                                                .latitude ||
                                                              _vm.mapCenter.lat,
                                                            lng:
                                                              _vm.reports[0]
                                                                .address
                                                                .geolocation
                                                                .longitude ||
                                                              _vm.mapCenter.lng
                                                          },
                                                          clickable: true,
                                                          draggable: true
                                                        }
                                                      }),
                                                      _vm.reportEarthquakes &&
                                                      _vm.reportEarthquakes
                                                        .length
                                                        ? _vm._l(
                                                            _vm.reportEarthquakes,
                                                            function(
                                                              earthquake,
                                                              earthquakeRadiusIndex
                                                            ) {
                                                              return _c(
                                                                "GmapCircle",
                                                                {
                                                                  key:
                                                                    "earthquakeRadius-" +
                                                                    earthquakeRadiusIndex,
                                                                  attrs: {
                                                                    center:
                                                                      earthquake.center,
                                                                    radius:
                                                                      earthquake.radius,
                                                                    visible: true,
                                                                    options: _vm.getCircleOptions(
                                                                      earthquake
                                                                    ),
                                                                    clickable: false
                                                                  }
                                                                }
                                                              )
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }